import { authorisedRequest } from './authorisedRequest';

export const fetchProviderDetails = (id: string) => {
  return authorisedRequest(`/admin/providers/${id}`);
}

export const fetchProviderPaymentMethods = (id: string) => {
  return authorisedRequest(`/admin/providers/${id}/payment-methods`);
}

export const fetchAvailableLanguages = () => {
  return authorisedRequest(`/languages`);
}

export const fetchOldDocuments = (id: string) => {
  return authorisedRequest(`/admin/providers/${id}/documents?statuses=ARCHIVE`);
}

export const approveProvider = (id: string) => {
  return authorisedRequest(`/admin/providers/${id}/activate`, 'PUT');
}

export const rejectProvider = (id: string, reason: string) => {
  return authorisedRequest(`/admin/providers/${id}/reject`, 'PUT', { comment: reason });
}

export const verifyProvider = (id: string) => {
  return authorisedRequest(`/admin/providers/${id}/payment-account/verify`, 'PUT');
}

export const updateProviderInfo = (id: string, payload: any) => {
  return authorisedRequest(`/admin/providers/${id}`, 'PUT', payload);
}

export const fetchAvailableWorkPlaces = () => {
  return authorisedRequest(`/service-cities`);
}

export const getProviderWorkingPlaces = (id: string) => {
  return authorisedRequest(`/admin/providers/${id}/service-areas`);
}

export const deleteProviderWorkingPlace = (id: string, payload: { serviceAreaIds: string[] }) => {
  return authorisedRequest(`/admin/providers/${id}/service-areas/detach`, 'PUT', payload);
}

export const addProviderWorkingPlace = (id: string, payload: {details: any[] }) => {
  return authorisedRequest(`/admin/providers/${id}/service-areas/attach`, 'PUT', payload);
}

export const getProvideWorkingPlacesByPostalCode = (text: string) => {
  return authorisedRequest(`/postal-codes?searchKey=${text}`, );
}

export const updateDocumentExpirationDate = (providerId: string, docId: string, payload: any) => {
  return authorisedRequest(`/admin/providers/${providerId}/documents/${docId}`, 'PUT', payload);
}

export const uploadNewDocuments = (providerId: string, docType: string, payload: any, ) => {
  return authorisedRequest(`/admin/providers/${providerId}/documents?type=${docType}`, 'POST', payload, { 'Content-Type': 'multipart/form-data'} );
}

export const acceptDocument = (providerId: string, docId: string,) => {
  return authorisedRequest(`/admin/providers/${providerId}/documents/${docId}/approve`, 'PUT');
}

export const rejectDocument = (providerId: string, docId: string,) => {
  return authorisedRequest(`/admin/providers/${providerId}/documents/${docId}/reject`, 'PUT', {});
}

export const archiveDocument = (providerId: string, docId: string,) => {
  return authorisedRequest(`/admin/providers/${providerId}/documents/${docId}/archive`, 'PUT', {});
}

export const completeSignUp = (providerId: string) => {
  return authorisedRequest(`/admin/providers/${providerId}/confirm-signup`, 'PUT');
}

export const changeComment = (userId: string, comment: string) => {
  return authorisedRequest(`/admin/users/${userId}/comment`, 'PUT', { text: comment, userType: "PROVIDER" })
}

export const exportProvidersData = ({ needsAction, outOfService, statuses }: { needsAction?: boolean, outOfService?: boolean, statuses: string}, filterConfigs: any) => {
  let querystring = '';
  if (needsAction) {
    querystring = 'needsAction=true';
  } else if (outOfService){
    querystring = `statuses=${statuses}&outOfService=true`;
  } else {
    querystring = `statuses=${statuses}`;
  }
  if (filterConfigs) {
    for (let [key, value] of Object.entries(filterConfigs)) {
      querystring += `&${key}=${value}`;
    }
  }
  return authorisedRequest(`/export/providers?${querystring}`)
}

export const fetchDuplicatedProviders = (providerId: string) => {
  return authorisedRequest(`/admin/providers/${providerId}/duplicate`);
}

export const addAdminComment = (providerId: string,type: string, documentTypeId: string, payload: any) => {
  return authorisedRequest(`/admin/providers/${providerId}/document-types/${documentTypeId}`, 'PUT', payload);
}

export const extendDocument = (providerId: string, docId: string, extended: boolean,) => {
  return authorisedRequest(`/admin/providers/${providerId}/documents/${docId}`, 'PUT', { extended });
}